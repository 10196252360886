import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeQtyAdjEntity } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjEntity";
import { INITIAL_CHARGE_QTY_ADJ_COL_DEF } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceColumnDefinition";
import { ChargeQtyAdjMaintenanceConstant } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const ChargeQtyAdjTablePanel: React.FC = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const gridRef: any = useRef(null);
    const { tableData } = chargeQtyAdjState;
    const uploadRef = useRef<any>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        chargeQtyAdjVM.updateSelectedRows(tableData, selectedRows);
    }, [chargeQtyAdjVM, tableData])
    const handleAddClick = useCallback(() => {
        chargeQtyAdjVM.onAdd();
    }, [chargeQtyAdjVM])
    const handleRowDoubleClick = useCallback((entity: ChargeQtyAdjEntity) => {
        chargeQtyAdjVM.onEdit(entity);
    }, [chargeQtyAdjVM])

    const memoBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onDeleteButton',
            icon: 'Icon-docs-add',
            title: 'Upload'
        })
        return btns;
    }, [])

    const handleUpload = () => {
        uploadRef.current?.click();
    }

    const handleFileChange = useCallback(async (e: any) => {

        const files = e.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showWarining('Please upload a .csv file');
            return
        }

        setLoading(true);
        const res = await chargeQtyAdjVM.onUpload(file);
        uploadRef.current.value = null;
        if (!res.success) {
            messageBarVM.showWarining(res.data ?? 'Failed to upload.');
        } else {
            await chargeQtyAdjVM.onSearch();
        }

        setLoading(false);

    }, [chargeQtyAdjVM, messageBarVM]);

    const memoTable = useMemo(() => {
        return (
            <HPHTable
                id='charge-qty-adj-table'
                headerLabel={ChargeQtyAdjMaintenanceConstant.TITLE}
                headerActionButtons={memoBtns}
                isNewColumnSetting={true}
                columns={INITIAL_CHARGE_QTY_ADJ_COL_DEF}
                data={tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                onDeleteButton={handleUpload}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: ChargeQtyAdjEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [memoBtns, tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])
    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <input ref={uploadRef} type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} />
    </>;
}
export default memo(ChargeQtyAdjTablePanel); 
