import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeQtyAdjMaintenanceConstant } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { INITIAL_CHARGE_QTY_ADJ_VESSEL_COL_DEF, transferRowData } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjVesselColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldType, HPHButton, HPHTable, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ChargeQtyAdjVesselTablePanel = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
  const [anaInfoState] = useANAInfoTracked();
  const {allowUpdate} = anaInfoState;
  const gridRef: any = useRef();
  const {chargeQtyAdjVesselData,vesselSearchCriteria,selectVeeselDatas} = chargeQtyAdjState;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const msg = 'Please input Charge-on Company, Bill-to Company, Consortium Code, Vessel Code, Voyage Code, Vessel Name, Arr Date/Time or Dep Date/Time at least;'; 
    
const handleApply = useCallback(async() => {    
    chargeQtyAdjVM.onVesselApply(selectVeeselDatas[0]);
}, [chargeQtyAdjVM, selectVeeselDatas]);

const handleReset = useCallback(async() => { 
    chargeQtyAdjVM.resetVesselSearchCriteria();
}, [chargeQtyAdjVM]);

const handleSearch = useCallback(async() => {   
    if(!vesselSearchCriteria.chargeOnCompany && !vesselSearchCriteria.billToCompany && !vesselSearchCriteria.conCode
        && !vesselSearchCriteria.voyCode && !vesselSearchCriteria.vslCode && !vesselSearchCriteria.vslName
        && !vesselSearchCriteria.arrDate && !vesselSearchCriteria.depDate
    ){
        setIsShow(true);
        return;
    }
    setIsLoading(true);
    chargeQtyAdjVM.initChargeQtyAdjVesselSearch(vesselSearchCriteria).then((data) =>{
        setIsLoading(false); 
    })
}, [chargeQtyAdjVM, vesselSearchCriteria]);

const isDisableApply = () => {
    return selectVeeselDatas.length === 1;
}

    useEffect(() => {
        const checkVesselSearchCriteria = async () => {
            if(vesselSearchCriteria.chargeOnCompany || vesselSearchCriteria.billToCompany || vesselSearchCriteria.conCode
                || vesselSearchCriteria.voyCode || vesselSearchCriteria.vslCode || vesselSearchCriteria.vslName || vesselSearchCriteria.arrDate || vesselSearchCriteria.depDate
            ){
                setIsShow(false);
            }
        }
        checkVesselSearchCriteria();

    }, [vesselSearchCriteria])

const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    chargeQtyAdjVM.updateSelectedVesselData(selectedRows);
}, [chargeQtyAdjVM])

const memoChargeOnCompany = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.chargeOnCompany || ''}
            fieldValue={vesselSearchCriteria?.chargeOnCompany}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.CHARGEON_COMPANY}
            isSaveClicked={false}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'chargeOnCompany'}
            maxLength={60}
            sort={false}
            options={chargeQtyAdjState.dynamicOptions.chargeOnCompDropdownOptions}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjState.dynamicOptions.chargeOnCompDropdownOptions, chargeQtyAdjVM, vesselSearchCriteria?.chargeOnCompany])

    const memoBillToCompany = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.billToCompany || ''}
            fieldValue={vesselSearchCriteria?.billToCompany}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.BILLTO_COMPANY}
            isSaveClicked={false}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'billToCompany'}
            maxLength={60}
            sort={false}
            options={chargeQtyAdjState.dynamicOptions.chargeOnCompDropdownOptions}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjState.dynamicOptions.chargeOnCompDropdownOptions, chargeQtyAdjVM, vesselSearchCriteria?.billToCompany])
    
    const memoConCode = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.conCode || ''}
            fieldValue={vesselSearchCriteria?.conCode}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.CONSORTIUM_CODE}
            isSaveClicked={false}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'conCode'}
            maxLength={60}
            sort={false}
            options={chargeQtyAdjState.dynamicOptions.consortiumCodeDropdownOptions}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjState.dynamicOptions.consortiumCodeDropdownOptions, chargeQtyAdjVM, vesselSearchCriteria?.conCode])
    
    const memoVslCode = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.vslCode || ''}
            fieldValue={vesselSearchCriteria?.vslCode}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.VESSEL_CODE}
            isSaveClicked={false}
            fieldType={FieldType.TEXT}
            fieldKey={'vslCode'}
            maxLength={60}
            sort={false}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjVM, vesselSearchCriteria?.vslCode])
    
    const memoVoyCode = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.voyCode || ''}
            fieldValue={vesselSearchCriteria?.voyCode}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.VOYAGE_CODE}
            isSaveClicked={false}
            fieldType={FieldType.TEXT}
            fieldKey={'voyCode'}
            maxLength={60}
            sort={false}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjVM, vesselSearchCriteria?.voyCode])
    
    const memoVslName = useMemo(() =>
    <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
        <NbisCommonField
            errorMessages={{}}
            isReadOnly={false}
            isShowOptional={false}
            readOnlyValue={vesselSearchCriteria?.vslName || ''}
            fieldValue={vesselSearchCriteria?.vslName}
            fieldLabel={ChargeQtyAdjMaintenanceConstant.VESSEL_NAME}
            isSaveClicked={false}
            fieldType={FieldType.TEXT}
            fieldKey={'vslName'}
            maxLength={60}
            sort={false}
            requiredFieldList={[]}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
    </div>
    , [chargeQtyAdjVM, vesselSearchCriteria?.vslName])
    
    const memoArrDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
            <DatePickerComponent
                disabled={false}
                label={ChargeQtyAdjMaintenanceConstant.ARR_DATETIME}
                width="240px"
                date={vesselSearchCriteria?.arrDate}
                fieldName="arrDate"
                optional={false}
                errorMessage={""}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue)} />
        </div>
        , [vesselSearchCriteria?.arrDate, chargeQtyAdjVM])

    const memoDepDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "240px", marginBottom: "15px" }}>
            <DatePickerComponent
                disabled={false}
                label={ChargeQtyAdjMaintenanceConstant.DEP_DATETIME}
                width="240px"
                date={vesselSearchCriteria?.depDate}
                fieldName="depDate"
                optional={false}
                errorMessage={""}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    chargeQtyAdjVM.onVesselSearchFieldChange(fieldKey, fieldValue)} />
        </div>
        , [vesselSearchCriteria?.depDate, chargeQtyAdjVM])


const memoChargeQtyAdjVesselTable = useMemo(() => {
    return <HPHTable
            id={'charge-qty-adj-vessel-table'}
            isNewColumnSetting={true}
            columns={INITIAL_CHARGE_QTY_ADJ_VESSEL_COL_DEF.slice()}
            data={transferRowData(chargeQtyAdjVesselData??[])}
            showPaginator={false}
            editable={false}
            rowSelection={"single"}
            showAddIcon={false}
            showDeleteButton={false}        
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(40vh)" 
            ref={gridRef}
        />
    },[chargeQtyAdjVesselData, handleSelectionChange])

    return <>{ <TableWrapper>
            <Sidebarheader style={{width: '100%'}}>
                <CriteriaItemContainer>
                    {memoChargeOnCompany}
                    {memoBillToCompany}
                    {memoConCode}
                    {memoVslCode}
                    {memoVoyCode}
                    {memoVslName}
                    {memoArrDate}
                    {memoDepDate}
                </CriteriaItemContainer>
            </Sidebarheader>
                <StyledAction className="tm-animated-wrapper">
                    <label style={{fontSize: '12px', color: 'red'}}>{(isShow)&&msg}</label>
            {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_RESET} size={'Small'} theme={'Secondary'} onClick={handleReset} />}                
            {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_SEARCH} size={'Small'} theme={'Primary'} onClick={handleSearch} />}                
        </StyledAction>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoChargeQtyAdjVesselTable}
        {<StyledAction className="tm-animated-wrapper">
            {(allowUpdate) && <HPHButton disabled={!isDisableApply()} label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={handleApply} />}                
        </StyledAction>}
        </TableWrapper>}</>;
}

export default memo(ChargeQtyAdjVesselTablePanel);