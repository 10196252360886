import { ChargeQtyAdjMaintenanceConstant } from "./ChargeQtyAdjMaintenanceConstant";
const SCREEN_CONSTANT = ChargeQtyAdjMaintenanceConstant;
export const INITIAL_CHARGE_QTY_ADJ_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        //pinned: "left", 
        //checkboxSelection: true, 
        //headerCheckboxSelection: true, 

    },
    {
        headerName: SCREEN_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGEON_COMPANY,
        field: "chargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CONSORTIUM_CODE,
        field: "consortiumCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.VESSEL_CODE,
        field: "vesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.VOYAGE_CODE,
        field: "voyageCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.HANDLING_TML,
        field: "handlingTerminal",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ETD,
        field: "etd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: SCREEN_CONSTANT.CONTAINER_ID,
        field: "displayCntrNos",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ALLOWANCE,
        field: "allowance",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.UOM,
        field: "uom",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
    },
    {
        headerName: SCREEN_CONSTANT.WAIVER_NO,
        field: "waiverNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.BILL_OF_LADING_NO,
        field: "blNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CUSTOMER_REF_NO,
        field: "customReferenceNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CFS_SERVICE_TYPE,
        field: "serviceType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.SO_NUMBER,
        field: "soNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
    },

] 
